.menu {
  position: fixed;   // Մենյուն հիմա ֆիքսված է վերևում
  top: 0;
  left: 0;
  width: 100%;
  background: #222;
  padding: 5px;
  font-family: "Poppins", sans-serif;
  z-index: 1000; // Համոզվելու համար, որ մենյուն ամենաբարձր շերտում է
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3); // Ստվեր, որպեսզի առանձնանա
  nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .hamburger {
    font-size: 28px;
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
    display: none; // Հեռացնում ենք մեծ էկրաններից

    &.active {
      transform: rotate(90deg);
    }
  }

  .menu-list {
    list-style: none;
    display: flex;
    gap: 15px;
    margin: 0;
    padding: 0;
    
    li {
      a, button {
        color: white;
        background-color: transparent;
        border: 0px;
        text-decoration: none;
        padding: 12px 18px;
        cursor: pointer;
        font-size: 16px;
        font-weight: 500;
        display: flex;
        align-items: center;
        transition: all 0.3s ease-in-out;
        gap: 5px;
        
        &:hover {
          color: #ffd700;
          transform: scale(1.05);
        }
      }
    }
  }

  // Responsive - Mobile View
  @media (max-width: 768px) {
    
    .menu {
      padding: 10px !important;
    }
    .menu_nav{
      display: flex;
      justify-content: end;
      padding: 0px 10px;
    }

    .hamburger {
      display: block; // Ցույց տալ փոքր էկրաններին
    }

    .menu-list {
      flex-direction: column;
      position: absolute;
      top: 52px;
      left: 0;
      width: 100%;
      background: rgba(0, 0, 0, 0.8);
      backdrop-filter: blur(10px);
      display: none;
      padding: 15px 0;
      border-radius: 0px 0px 10px 10px;
      transition: all 0.4s ease-in-out;
      transform: translateY(-20px);
      opacity: 0;
    }

    .menu-list.open {
      display: flex;
      transform: translateY(0);
      opacity: 1;
      animation: fadeIn 0.4s ease-in-out;
    }
  }
}

// Անիմացիա
@keyframes fadeIn {
  from {
    transform: translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
