.Income{
    padding:60px 10px  10px 10px;
    .income_sum{
        display: flex;
        justify-content: space-between;
        padding:10px 20px;
        span{
            font-weight: 700;
            font-size: 17px;
        }
    }
    .btn_col {
        padding: 0;
        background-color: transparent;
        color: black;
        border: none;
        cursor: pointer;
      }
    
}