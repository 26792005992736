.Purchase{
    padding:60px 10px  10px 10px;

    .btn_c {
        padding: 0;
        background-color: transparent;
        color: black;
        border: none;
        cursor: pointer;
      }
      .btn_no_color{
        background: transparent !important;
        border: 0px !important;
    }
    
}