.Payment{
    
    padding: 70px 10px 10px;
    .btn_col {
        padding: 0;
        background-color: transparent;
        color: black;
        border: none;
        cursor: pointer;
      }
    .color_buyer td{
        color: rgb(11, 24, 142);
        font-weight: 700;
      }

      .tab_db{
        button{
          padding: 5px 0px;
        }
      }
}